import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import { HomeService } from 'src/app/shared/services/home.service';
import { Home } from 'src/app/shared/model/home.model';
import { milliseconds } from 'date-fns';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
//import { SwiperComponent } from '../swiper/swiper.component';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;
  private home: Home;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Italian',
    code: 'it',
    type: 'IT',
    icon: 'it'
  },
  {
    language: 'Hungarian',
    code: 'hu',
    type: 'HU',
    icon: 'hu'
  },
  {
     language: 'Español',
     code: 'es',
     type: 'ES',
     icon: 'es'
  },
  {
     language: 'Français',
     code: 'fr',
     type: 'FR',
     icon: 'fr'
   },
   {
     language: 'Português',
     code: 'pt',
     type: 'BR',
     icon: 'pt'
   },
   {
    language: 'German',
    code: 'de',
    type: 'DE',
    icon: 'de'
  }
]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  constructor(public navServices: NavService, private translate: TranslateService, private homeService: HomeService) { }

  ngOnInit() {
    this.getHome();
  }

  getHome(){ 
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        console.log(this.home);
        if(this.home!= null && this.home.defaultLanguage != null && this.home.defaultLanguage != ""){
            this.changeLanguage(this.languages.find(x => x.code == this.home.defaultLanguage));
        } else {
          this.changeLanguage("hu");
        }
        console.log("Home based selected language: " + this.selectedLanguage.code);
      });
  }

  changeLanguage(lang) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    console.log("Selected language: " + this.selectedLanguage.code);
  }
}