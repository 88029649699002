import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { AdminGuard } from './shared/guard/admin.guard';
import { LoginComponent } from './pages/authentication/login/login.component';
import { RegisterSimpleComponent } from './pages/authentication/register/register/register.component';
import { ForgetPasswordComponent } from './pages/authentication/forget-password/forget-password.component';
import { InstallComponent } from './pages/authentication/install/install.component';
import { ResetPasswordComponent } from './pages/authentication/reset-password/reset-password.component';
import { ConfirmEmailOutComponent } from './pages/authentication/confirm-email-out/confirm-email-out.component';
import { ImportComponent } from './components/apps-fittingker/import/import/import.component';


const paths = {
  login: 'authentication/login',
  register: 'authentication/register',
  forgot: 'authentication/forgot-password',
  reset: 'authentication/reset-password',
  install: 'authentication/install',
  confirm: 'authentication/confirm-email'
}

const routes: Routes = [
  {
    path: paths.login,
    component: LoginComponent
  },
  {
    path: paths.install,
    component: InstallComponent
  },
  // { TODO: Deny register in public release
  //   path: paths.register,
  //   component: RegisterSimpleComponent
  // },
  {
    path: paths.forgot,
    component: ForgetPasswordComponent
  },
  {
    path: paths.reset,
    component: ResetPasswordComponent
  },
  {
    path: paths.confirm,
    component: ConfirmEmailOutComponent
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AdminGuard],
    children: full
  },
  {
    path: '**',
    redirectTo: "/home"
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
